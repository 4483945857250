import Skeleton from "@mui/material/Skeleton";

export default function AgentList({
	lockedAgents,
	setLockedAgents,
	completeAgentList,
}) {
	return (
		<div className="flex gap-x-5 overflow-auto w-full text-valowhite">
			{!completeAgentList.isLoading ? (
				completeAgentList.data.data.data.map((agent) => {
					return (
						<div key={agent.uuid}>
							<button
								className="transition ease-in-out hover:-translate-y-1 hover:scale-150 duration-300"
								onClick={() => {
									if (
										lockedAgents.filter(
											(lockedAgent) =>
												lockedAgent.displayName === agent.displayName
										).length > 0
									) {
										setLockedAgents(
											lockedAgents.filter(
												(lockedAgent) => lockedAgent.uuid !== agent.uuid
											)
										);
									} else {
										setLockedAgents([...lockedAgents, agent]);
									}
								}}
							>
								<div className="w-16 h-16 relative">
									<img
										src={agent.imageSrc}
										alt="AgentDisplay"
										className="rounded-full"
									/>
									<img
										src="/red-cross.png"
										alt="red-cross"
										className={`absolute top-0 ${
											lockedAgents.filter(
												(lockedAgent) =>
													lockedAgent.displayName === agent.displayName
											).length === 0 && "hidden"
										}`}
									/>
								</div>
							</button>
						</div>
					);
				})
			) : (
				<Skeleton variant="rectangular" width="100%" height="64px" />
			)}
		</div>
	);
}
