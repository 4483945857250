import { useEffect, useRef } from "react";

export default function Popup({ children, showPopup, setShowPopup }) {
	useEffect(() => {
		if (showPopup) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [showPopup]);

	let popupRef = useRef();
	useEffect(() => {
		let handler = (e) => {
			if (popupRef.current && !popupRef.current.contains(e.target)) {
				setShowPopup(false);
			}
		};
		document.addEventListener("mousedown", handler);

		return () => {
			document.removeEventListener("mousedown", handler);
		};
	});

	return (
		<>
			{showPopup && (
				<div className="z-100 fixed w-screen h-screen bg-black/50 flex justify-center items-center">
					<div
						ref={popupRef}
						className="relative w-full h-full bg-popup p-4 text-popupwhitetext"
					>
						<img
							src="/valodeals_font.png"
							alt="logofont"
							className="h-8 ml-5 mt-5"
						/>
						<button onClick={() => setShowPopup(false)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5 absolute top-8 right-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						{children}
					</div>
				</div>
			)}
		</>
	);
}
