import Button from "../../components/Button/RedButton";
import InformationCard from "./InformationCard/Index";
import TextArea from "../../components/Input/TextArea";
import TextInput from "../../components/Input/TextInput";
import { axios } from "../../constants";
import { useState } from "react";

export default function Contact() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [line, setLine] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);

	const handleSubmit = async () => {
		setLoading(true);
		await axios.post("create_contactform/", {
			name: name,
			email: email,
			phone: phone,
			line: line,
			message: message,
		});
		setLoading(false);
	};

	return (
		<div className="flex flex-col gap-y-10 justify-center items-center mt-10 md:mt-20 md:mb-40">
			<h1 className="text-4xl font-black">Hubungi Kami</h1>
			<div className="md:hidden flex justify-center w-full tny:w-5/6 sm:w-3/5">
				<InformationCard />
			</div>
			<div className="w-full flex justify-center">
				<div className="flex flex-col items-center justify-center gap-y-2 w-full md:rounded-3xl md:justify-start md:flex-row md:bg-second md:w-5/6 lg:w-4/5">
					<div className="w-full md:w-5/6 lg:w-2/3 xl:w-1/2 hidden md:block">
						<InformationCard />
					</div>
					<div className="w-full flex flex-col items-center">
						<div className="w-full flex flex-col items-center md:flex-row tny:px-10 md:px-20">
							<div className="w-4/5 md:w-1/2 md:pr-5">
								<TextInput
									value={name}
									setValue={setName}
									label="Nama Lengkap"
									fit={true}
								/>
							</div>
							<div className="w-4/5 md:w-1/2 md:pl-5">
								<TextInput
									value={email}
									setValue={setEmail}
									label="Alamat E-mail"
									fit={true}
								/>
							</div>
						</div>
						<div className="w-full flex flex-col items-center md:flex-row tny:px-10 md:px-20">
							<div className="w-4/5 md:w-1/2 md:pr-5">
								<TextInput
									value={phone}
									setValue={setPhone}
									label="Nomor Handphone"
									fit={true}
								/>
							</div>
							<div className="w-4/5 md:w-1/2 md:pl-5">
								<TextInput
									value={line}
									setValue={setLine}
									label="Line ID"
									fit={true}
								/>
							</div>
						</div>
						<div className="w-full flex justify-center tny:px-10 md:px-20">
							<div className="w-4/5 md:w-full">
								<TextArea
									value={message}
									setValue={setMessage}
									label="Pesan"
									fit={true}
									height={200}
								/>
							</div>
						</div>
						<div className="my-10 w-52 flex justify-center items-center md:justify-end">
							<Button
								label="Kirim Pesan"
								onClick={handleSubmit}
								disabled={
									message === "" ||
									name === "" ||
									email === "" ||
									phone === "" ||
									line === ""
								}
								height={50}
								onSuccess="Pesan Anda akan segera dibaca Admin"
								isLoading={loading}
								fit={true}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
