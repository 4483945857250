function Screenshot({ screenshotImageSrc, showScreenshot, setShowScreenshot }) {
	return (
		<div className="w-full flex justify-center">
			<img
				src={screenshotImageSrc || "/imagenotfound.png"}
				alt="Screenshot Preview"
				className={`my-5 transition-all duration-500 ${
					showScreenshot ? "w-full" : "w-0"
				}`}
			/>
		</div>
	);
}

export default Screenshot;
