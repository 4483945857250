export default function MenuIcon({ showMenu, setShowMenu }) {
	return (
		<button onClick={() => setShowMenu(!showMenu)}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="h-8 w-8 absolute right-0 bottom-5 mr-5"
				fill="none"
				viewBox="0 0 24 24"
				stroke="white"
				strokeWidth={2}
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M4 6h16M4 12h16M4 18h16"
				/>
			</svg>
		</button>
	);
}
