export default function Logo({ navigate }) {
	return (
		<div className="h-full flex items-end">
			<button
				onClick={() => navigate("/")}
				className="flex flex-row gap-x-4 justify-start items-center mb-1 h-1/2"
			>
				<img src="/valodeals_font.png" alt="logofont" className="h-full" />
				<h1 className="hidden sm:block lg:hidden 2xl:block text-sm md:text-base font-valorant text-white font-bold">
					vALODEALS
				</h1>
			</button>
		</div>
	);
}
