export const MENUS = ["Home", "Bantuan", "Shop", "Others"];

export const SUBMENU = {
	Home: [],
	Support: [
		{ id: 0, title: "FAQs", path: "/faqs" },
		{ id: 1, title: "Peraturan", path: "/rules" },
		{ id: 2, title: "Tentang Valodeals", path: "/about" },
		{ id: 3, title: "Kontak", path: "/contact" },
		{ id: 4, title: "Syarat dan Ketentuan", path: "/terms" },
		{ id: 5, title: "Kebijakan Privasi", path: "/privacy" },
	],
	Seller: [
		{ id: 0, title: "Jual Akun", path: "/mylisting" },
		{ id: 1, title: "Edit Produk", path: "/find" },
	],
	Explore: [
		{ id: 0, title: "Cari Akun", path: "/explore" },
		{ id: 1, title: "Galeri Skin", path: "/gallery" },
	],
};
