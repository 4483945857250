import {
	EMAIL_CONTACT,
	INSTAGRAM_ID,
	LINE_OFFICIAL_ID,
	WHATSAPP_CONTACT,
} from "../../../constants";

function InformationCard() {
	return (
		<div className="w-full relative text-white mx-5 md:mx-0">
			<img
				src="/contactbg.png"
				alt="contact red background"
				className="w-full rounded-3xl"
			/>
			<div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col gap-y-5 lg:gap-y-10 p-10">
				<h1 className="text-lg tny:text-2xl md:text-xl lg:text-3xl font-bold">
					Informasi Kontak
				</h1>
				<h1 className="text-sm xl:text-lg text-valowhite3 font-normal">
					Isi form ini dan tim Valodeals akan segera menghubungi Anda paling
					lambat 24 jam setelah pengisian form
				</h1>
				<div className="flex flex-col gap-y-5 text-xs sm:text-sm md:text-md 2xl:text-xl">
					<div className="w-full flex flex-row items-center gap-x-8">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-8 w-8"
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
						</svg>
						<h1>{WHATSAPP_CONTACT}</h1>
					</div>
					<div className="w-full flex flex-row items-center gap-x-8">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-8 w-8"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth={2}
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
							/>
						</svg>
						<h1>{EMAIL_CONTACT}</h1>
					</div>
					<div className="w-full flex flex-row items-center gap-x-8">
						<img src="/line.png" alt="line logo" className="w-8" />
						<h1>{LINE_OFFICIAL_ID}</h1>
					</div>
					<div className="w-full flex flex-row items-center gap-x-8">
						<img src="/instagram.png" alt="line logo" className="w-8" />
						<h1>{INSTAGRAM_ID}</h1>
					</div>
				</div>
			</div>
		</div>
	);
}

export default InformationCard;
