import { REKENING_NUMBER } from "../../../../../constants";
import toast from "react-hot-toast";

function Rekening() {
	const copyToClipboard = () => {
		try {
			navigator.clipboard.writeText(REKENING_NUMBER);
			toast.success("Nomor Rekening telah disalin");
		} catch (err) {
			toast.error("Your browser doesn't support copying to clipboard");
		}
	};

	return (
		<div className="flex w-full text-2xl my-5">
			<input
				type="text"
				id="rek"
				defaultValue={REKENING_NUMBER}
				className="py-2 px-5 w-full sm:w-60 md:w-80 text-sm sm:text-lg md:text-xl bg-white text-valoblack rounded-tl-xl rounded-bl-xl"
			/>
			<button
				onClick={copyToClipboard}
				className="p-2 px-3 bg-redButton hover:bg-hoverButton rounded-tr-xl rounded-br-xl"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-6 w-6"
					fill="none"
					viewBox="0 0 24 24"
					stroke="white"
					strokeWidth={2}
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
					/>
				</svg>
			</button>
		</div>
	);
}

export default Rekening;
