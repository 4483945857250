import { TextField } from "@mui/material";

function Second({ line, setLine }) {
	return (
		<div className="flex flex-col gap-y-5 mb-10">
			Setelah pembayaran dikonfirmasi, Anda akan diundang ke grup line bersama
			dengan penjual dan admin.
			<div className="w-full sm:w-60 md:w-80">
				<TextField
					fullWidth
					name="line"
					variant="filled"
					label="Line ID"
					size="small"
					value={line}
					onChange={(e) => setLine(e.target.value)}
				/>
			</div>
		</div>
	);
}

export default Second;
