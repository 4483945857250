import Button from "../Button/RedButton";
import { LINE_OFFICIAL_LINK } from "../../constants";
import { useNavigate } from "react-router-dom";

const hoverClassname =
	"transition-all duration-100 hover:text-valogray text-left";

function Footer() {
	const navigate = useNavigate();

	return (
		<div className="relative flex flex-col w-full bg-valoblack text-valowhite2">
			<div className="flex flex-row w-full">
				<div className="w-1/2 flex flex-col gap-y-5">
					<div className="flex flex-row items-center gap-x-3 ml-8 mt-8">
						<img src="/logo.png" alt="logo" className="w-14" />
						<h1 className="font-bold text-xl">ValoDeals</h1>
					</div>
					<div className="flex flex-col w-full ml-8 xl:ml-24 gap-y-6">
						<div className="w-2/3 sm:w-full flex flex-row items-center">
							<div className="h-0.5 w-10 bg-valored"></div>
							<div className="md:w-full mx-4 grow-0 text-[10px] sm:text-md md:text-lg font-bold text-valored">
								JELAJAHI PILIHAN ANDA SEKARANG
							</div>
						</div>
						<h1 className="font-bold text-xs sm:text-lg xl:text-4xl text-valowhite2 w-2/3 xl:w-1/2">
							Tunggu Apa Lagi? Ayo Beli Akun Valorantmu Sekarang
						</h1>
						<div className="text-sm sm:text-base w-48 xl:w-60">
							<Button
								label="Belanja Sekarang!"
								onClick={() => navigate("/explore")}
								fit={true}
								height={50}
							/>
						</div>
					</div>
				</div>
				<div className="relative w-1/2 flex flex-col mt-28 gap-y-3">
					<div className="flex flex-row gap-x-3 text-valored font-bold text-[11px] sm:text-md md:text-lg">
						<div className="w-1/2 text-left">SERVIS</div>
						<div className="w-1/2 text-left">VALODEALS</div>
					</div>
					<div className="flex flex-row gap-x-3 font-normal text-[11px] sm:text-sm md:text-md">
						<div className="w-1/2">
							<button
								onClick={() => navigate("/find")}
								className={hoverClassname}
							>
								Edit Produk Anda
							</button>
						</div>
						<div className="w-1/2">
							<button
								className={hoverClassname}
								onClick={() => navigate("/about")}
							>
								Tentang Valodeals
							</button>
						</div>
					</div>
					<div className="flex flex-row gap-x-3 font-normal text-[11px] sm:text-sm xl:text-md">
						<div className="w-1/2">
							<button
								className={hoverClassname}
								onClick={() => navigate("/mylisting")}
							>
								Jual Akun
							</button>
						</div>
						<div className="w-1/2">
							<button
								className={hoverClassname}
								onClick={() => navigate("/faqs")}
							>
								FAQs
							</button>
						</div>
					</div>
					<div className="flex flex-row gap-x-3 font-normal text-[11px] sm:text-sm xl:text-md">
						<div className="w-1/2">
							<button
								className={hoverClassname}
								onClick={() => navigate("/gallery")}
							>
								Galeri Skin
							</button>
						</div>
						<div className="w-1/2">
							<button
								className={hoverClassname}
								onClick={() => navigate("/contact")}
							>
								Customer Service
							</button>
						</div>
					</div>
					<div className="flex flex-row gap-x-3 font-normal text-[11px] sm:text-sm xl:text-md">
						<div className="w-1/2">
							<button
								className={hoverClassname}
								// onClick={() => navigate("/gallery")}
							>
								{/* Galeri Skin */}
							</button>
						</div>
						<div className="w-1/2">
							<button
								className={hoverClassname}
								onClick={() => navigate("/rules")}
							>
								Peraturan Dagang
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-10 flex flex-row justify-between gap-x-10 w-full font-normal text-sm">
				<div className="ml-8 xl:ml-24 flex flex-row items-center gap-x-5 w-1/2">
					<button
						onClick={() => navigate("/terms")}
						className="text-[12px] sm:text-sm md:text-md hover:text-valogray"
					>
						Syarat dan Ketentuan
					</button>
					<button
						onClick={() => navigate("/privacy")}
						className="text-[12px] sm:text-sm md:text-md hover:text-valogray"
					>
						Kebijakan Privasi
					</button>
				</div>
				<div className="flex flex-row grow justify-end items-center gap-x-7 mr-5 md:mr-16 w-1/2">
					<a href={LINE_OFFICIAL_LINK}>
						<img src="/line.png" alt="line" className="w-9" />
					</a>
					<a href="https://www.instagram.com/valodeals/">
						<img src="/instagram.png" alt="instagram" className="w-9" />
					</a>
				</div>
			</div>
			<div className="flex justify-center font-medium py-5">
				Copyright 2022 ValoDeals
			</div>
		</div>
	);
}

export default Footer;
