import { useEffect, useState } from "react";

import { Reformat } from "../../components/PriceInRupiah/Price";
import { Skeleton } from "@mui/material";
import { axios } from "../../constants";
import html2canvas from "html2canvas";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useWindowSize } from "../../hooks/useWindowSize";

const ContactBar = () => {
	return (
		<div className="h-[135px] flex items-center">
			<div className="relative pb-6 flex items-center justify-center gap-x-10 w-full bg-valored/70 font-extrabold text-xl text-white font-montserrat">
				<div>GET IN TOUCH</div>
				<div>{"//"}</div>
				<img
					src="/logo.png"
					alt="Website"
					className="absolute top-0 bottom-0 my-auto left-[368px] h-9"
				/>
				<img
					src="/instagram_fullcolor.png"
					alt="Instagram"
					className="absolute top-0 bottom-0 my-auto left-[595px] h-6"
				/>
				<img
					src="/line_fullcolor.png"
					alt="Line"
					className="absolute top-0 bottom-0 my-auto right-[267px] h-6"
				/>
				<div className="flex gap-x-2">
					<div className="w-6 h-6"></div>
					<div>valodeals.net</div>
				</div>
				<div className="flex gap-x-2">
					<div className="w-6 h-6"></div>
					<div>@valodeals</div>
				</div>
				<div className="flex gap-x-2">
					<div className="w-6 h-6"></div>
					<div>@042oqyle</div>
				</div>
			</div>
		</div>
	);
};

function Capture() {
	const windowSize = useWindowSize();
	const params = useParams();
	const listing = useQuery(["listing", params.listing_id], async () => {
		return await axios.get(`get_listing_detail/${params.listing_id}/`);
	});
	const [done, setDone] = useState(false);
	const [screenTooSmall, setScreenTooSmall] = useState(
		(windowSize.width ?? 1280) < 1280
	);

	useEffect(() => {
		setScreenTooSmall(windowSize.width < 1280);
		if (windowSize.width >= 1280) {
			if (!listing.isLoading && !done) {
				html2canvas(document.querySelector("#instagram"), {
					allowTaint: true,
				}).then((canvas) => {
					document.getElementById("canvas").innerHTML = "";
					document.getElementById("canvas").append(canvas);
					const image = canvas
						.toDataURL("image/png")
						.replace("image/png", "image/octet-stream");
					const a = document.createElement("a");
					toast.success("Image has been created!");
					a.setAttribute("download", `${listing.data.data.id}.png`);
					a.setAttribute("href", image);
					a.click();
					canvas.remove();
					setDone(true);
				});
			}
		}
	}, [windowSize.width, listing, done]);

	return (
		<div className="py-20 flex flex-col gap-y-10 items-center justify-center">
			{listing.isLoading ? (
				<div className="w-[1080px]">
					<Skeleton width="100%" height="1350px" variant="rectangular" />
				</div>
			) : screenTooSmall ? (
				<div className="px-5 h-[calc(100vh-280px)] flex flex-col items-center justify-center">
					<img
						src="/jettkawai.png"
						alt="Cute Jett"
						className="w-[250px] h-[250px] sm:w-[400px] sm:h-[400px]"
					/>
					<div className="text-center text-lg sm:text-xl lg:text-2xl font-valorant">
						Feature only available on desktop
					</div>
				</div>
			) : (
				<div>
					<div className="flex flex-col gap-y-10 justify-center items-center">
						<div className="relative">
							<div className="absolute top-0 bottom-0 left-0 right-0 bg-black z-70"></div>
							<div
								id="instagram"
								className="relative overflow-auto w-[1080px] h-[1350px] flex flex-col items-center"
							>
								<div className="absolute z-50 -top-5 left-0 right-0 h-[135px] bg-gradient-to-b from-valored/40 to-[#0F1923]"></div>
								<div
									style={{
										WebkitTextStroke: "2px",
										WebkitTextStrokeColor: "white",
									}}
									className="absolute z-60 -top-12 left-0 right-0 h-[135px] flex gap-x-7 justify-center text-transparent font-valorant text-[100px] font-black tracking-widest"
								>
									<h1>ACCOUNT</h1>
									<h1>LISTING</h1>
								</div>
								<div className="absolute z-50 bottom-[354px] left-0 tracking-wide flex gap-x-10 text-8xl text-captureText font-bold font-anton">
									<h1>MUDAH</h1>
									<h1>CEPAT</h1>
									<h1>AMAN</h1>
								</div>
								<div className="absolute z-50 bottom-[254px] left-0 tracking-wide flex gap-x-10 text-8xl text-captureText font-bold font-anton">
									<h1>MUDAH</h1>
									<h1>CEPAT</h1>
									<h1>AMAN</h1>
								</div>
								<div className="absolute z-50 bottom-[154px] left-0 tracking-wide flex gap-x-10 text-8xl text-captureText font-bold font-anton">
									<h1>MUDAH</h1>
									<h1>CEPAT</h1>
									<h1>AMAN</h1>
								</div>
								{listing.data.data.discounted ? (
									<>
										<div className="absolute z-50 bottom-[350px] right-0 w-[240px] h-[100px] tracking-wide rounded-bl-3xl bg-valored/70 flex flex-col items-center justify-center gap-y-1 text-xl text-white font-bold font-valorant"></div>
										<div className="absolute z-50 bottom-[376px] right-0 w-[240px] tracking-wide flex flex-col items-center justify-center gap-y-1 text-2xl text-white font-bold font-valorant">
											<h1 className="text-4xl">HEMAT</h1>
											<h1 className="text-2xl">
												Rp
												{Reformat(
													listing.data.data.total_price -
														listing.data.data.discounted_price
												)}
											</h1>
										</div>
										<div className="z-60 absolute left-0 right-0 bottom-[300px] flex flex-col gap-y-1 justify-center items-center text-captureYellow font-valorant font-bold text-7xl">
											<div className="text-5xl relative px-3">
												<div className="absolute left-0 right-0 -bottom-10 top-0 flex items-center">
													<div className="h-1.5 w-full bg-valored"></div>
												</div>
												RP. {Reformat(listing.data.data.total_price)}
											</div>
											<h1>
												RP. {Reformat(listing.data.data.discounted_price)}
											</h1>
										</div>
										<div className=""></div>
									</>
								) : (
									<div className="z-60 absolute left-0 right-0 bottom-[340px] flex justify-center text-captureYellow font-valorant font-bold text-7xl">
										RP. {Reformat(listing.data.data.discounted_price)}
									</div>
								)}
								<div
									className={`z-60 font-montserrat text-4xl text-white font-bold absolute left-0 right-0 ${
										listing.data.data.discounted
											? "bottom-[220px]"
											: "bottom-[260px]"
									} flex gap-x-10 justify-center`}
								>
									<h1>CODE</h1>
									<h1>{"//"}</h1>
									<h1>{listing.data.data.id}</h1>
								</div>
								<div
									className={`absolute z-60 ${
										listing.data.data.discounted
											? "bottom-[160px]"
											: "bottom-[180px]"
									} left-0 right-0 flex justify-center text-white text-2xl font-medium font-montserrat`}
								>
									valodeals.net/listing/{listing.data.data.id}
								</div>
								<img
									src="/capture_bg.png"
									alt="Capture Background"
									className="z-10 absolute top-0 bottom-0 left-0 right-0"
								/>
								<img
									src="/capture_spiral.png"
									alt="Spiral"
									className="z-50 absolute top-24 right-5 w-[530px]"
								/>
								<div className="z-40 absolute left-0 right-0 top-44 flex justify-center">
									<img src="/valodeals_font.png" alt="Logo" className="h-20" />
								</div>
								<div className="z-50 absolute left-0 right-0 bottom-0">
									<ContactBar />
								</div>
								<div className="z-60 absolute left-0 right-0 top-[290px]">
									<img
										src={listing.data.data.screenshotImageSrc}
										alt="Listing Screenshot"
										className="w-full h-[610px] object-cover object-top"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<div id="canvas"></div>
		</div>
	);
}

export default Capture;
