import Popup from "./Popup";
import { useLocation } from "react-router-dom";

export default function Menu(props) {
	const location = useLocation();

	return (
		<Popup setShowPopup={props.setShowMenu} showPopup={props.showMenu}>
			<div className="px-5 pt-3 flex flex-col gap-y-2 w-full h-full">
				<button
					onClick={() => {
						props.setShowMenu(false);
						props.navigate("/");
					}}
					className={`pl-5 flex justify-start items-center w-full h-10 rounded-xl font-bold ${
						location.pathname === "/" && "bg-white/50"
					}`}
				>
					Home
				</button>
				<button
					className={`pl-5 flex justify-start items-center w-full h-10 relative font-medium rounded-xl hover:bg-yellow-400 ${
						location.pathname === "/giveaway" && "bg-yellow-400"
					}`}
					onClick={() => {
						props.setShowMenu(false);
						props.navigate("/giveaway");
					}}
				>
					<div className="text-red-500 relative font-bold flex items-center gap-x-2">
						<h1>Giveaway</h1>
						<img src="/gift.png" alt="Gift" className="w-5 h-5" />
					</div>
				</button>
				<button
					onClick={() => {
						props.setShowMenu(false);
						props.navigate("/explore");
					}}
					className={`pl-5 flex justify-start items-center w-full h-10 rounded-xl font-bold ${
						location.pathname === "/explore" && "bg-white/50"
					}`}
				>
					Cari Akun
				</button>
				<button
					onClick={() => {
						props.setShowMenu(false);
						props.navigate("/mylisting");
					}}
					className={`pl-5 flex justify-start items-center w-full h-10 rounded-xl font-bold ${
						location.pathname === "/mylisting" && "bg-white/50"
					}`}
				>
					Jual Akun
				</button>
				<button
					onClick={() => {
						props.setShowMenu(false);
						props.navigate("/gallery");
					}}
					className={`pl-5 flex justify-start items-center w-full h-10 rounded-xl font-bold ${
						location.pathname === "/gallery" && "bg-white/50"
					}`}
				>
					Galeri Skin
				</button>
				<button
					onClick={() => {
						props.setShowMenu(false);
						props.navigate("/find");
					}}
					className={`pl-5 flex justify-start items-center w-full h-10 rounded-xl font-bold ${
						(location.pathname.includes("/edit") ||
							location.pathname.includes("/find")) &&
						"bg-white/50"
					}`}
				>
					Edit Produk
				</button>
				<button
					onClick={() => {
						props.setShowMenu(false);
						props.navigate("/contact");
					}}
					className={`pl-5 flex justify-start items-center w-full h-10 rounded-xl font-bold ${
						location.pathname === "/contact" && "bg-white/50"
					}`}
				>
					Kontak
				</button>
				<button
					onClick={() => {
						props.setShowMenu(false);
						props.navigate("/about");
					}}
					className={`pl-5 flex justify-start items-center w-full h-10 rounded-xl font-bold ${
						location.pathname === "/about" && "bg-white/50"
					}`}
				>
					Tentang Valodeals
				</button>
				<button
					onClick={() => {
						props.setShowMenu(false);
						props.navigate("/rules");
					}}
					className={`pl-5 flex justify-start items-center w-full h-10 rounded-xl font-bold ${
						location.pathname === "/rules" && "bg-white/50"
					}`}
				>
					Peraturan
				</button>
			</div>
		</Popup>
	);
}
