import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./slider.css";

import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect, useRef, useState } from "react";

import AOS from "aos";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { Toaster } from "./components/Toaster";
import { useWindowScrollPosition } from "rooks";

const queryClient = new QueryClient();

const Provider = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
	);
};

function App() {
	useEffect(() => {
		AOS.init({ duration: 1500 });
		AOS.refresh();
	}, []);
	const navbarRef = useRef();
	const position = useWindowScrollPosition();
	const [visible, setVisible] = useState(position.scrollY > 100);
	useEffect(() => {
		if (position.scrollY > 100) {
			setVisible(true);
		} else {
			setVisible(false);
		}
	}, [position]);

	return (
		<Provider>
			<div className="relative font-medium font-poppins">
				<div ref={navbarRef}>
					<Navbar />
				</div>
				<div className="h-20" />
				{visible && (
					<button
						onClick={() => {
							if (navbarRef.current) {
								navbarRef.current.scrollIntoView({ behavior: "smooth" });
							}
						}}
						data-aos="fade-up"
						data-aos-duration="400"
						className="z-50 fixed bottom-5 right-5"
					>
						<div className="p-2 bg-redButton text-white rounded-full shadow-xl">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={2}
								stroke="currentColor"
								className="w-8 h-8 sm:w-10 sm:h-10"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
								/>
							</svg>
						</div>
					</button>
				)}
				<ScrollToTop>
					<Outlet />
					<div className="h-1 bg-redButton w-full"></div>
					<Footer />
					<Toaster />
				</ScrollToTop>
			</div>
		</Provider>
	);
}

export default App;
