import Axios from "axios";
import { Deformat } from "./components/PriceInRupiah/Price";

export const REKENING_OWNER = "Parvesh Mohan Bahirwani";

export const REKENING_NUMBER = "1342366435";

export const WHATSAPP_CONTACT = "+62 878-8016-1817";

export const LINE_OFFICIAL_ID = "@042oqyle";

export const LINE_OFFICIAL_LINK = "https://line.me/ti/p/~@042oqyle";

export const EMAIL_CONTACT = "valodealss@gmail.com";

export const INSTAGRAM_ID = "valodeals";

export const DOMAIN = "https://www.valodeals.net/valoshop/";

export const axios = Axios.create({ baseURL: DOMAIN });

export const VP_TO_RUPIAH = 55;

export const getTotalPrice = (skins, valorantPoints) => {
	let totalPrice =
		valorantPoints === null || valorantPoints === ""
			? 0
			: Deformat(valorantPoints);
	for (let skin of skins) {
		if (skin.price <= 1275) continue;
		totalPrice += skin.price;
	}
	totalPrice *= VP_TO_RUPIAH;
	return totalPrice;
};

export const axiosWithCredentials = Axios.create({
	baseURL: DOMAIN,
	withCredentials: true,
});

export const within = (x, l, r) => {
	return l <= x && x <= r;
};
