import { axios, axiosWithCredentials } from "../../constants";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Back from "./Back/Back";
import Button from "./DoneButton/Button";
import First from "./Procedures/First/First";
import Invalid from "./Invalid/Invalid";
import Pending from "./Pending/Index";
import Second from "./Procedures/Second/Second";
import { useQuery } from "react-query";
import { useState } from "react";
import { useTimer } from "react-timer-hook";

const Timer = ({ expireTime }) => {
	const expiryTimestamp = new Date(expireTime);
	const { seconds, minutes } = useTimer({
		expiryTimestamp,
	});

	return (
		<div className="pt-5 sm:pt-10 flex flex-col gap-y-3 items-center justify-center">
			<div className="text-base sm:text-xl">Waktu tersisa</div>
			<div className="flex gap-x-4 font-montserrat font-semibold text-2xl sm:text-3xl">
				<div className="flex flex-col items-center">
					<div className="flex gap-x-0.5">
						<div>{Math.floor(minutes / 10)}</div>
						<div>{minutes % 10}</div>
					</div>
					<div className="text-xs sm:text-sm font-medium">Menit</div>
				</div>
				:
				<div className="flex flex-col items-center">
					<div className="flex gap-x-0.5">
						<div>{Math.floor(seconds / 10)}</div>
						<div>{seconds % 10}</div>
					</div>
					<div className="text-xs sm:text-sm font-medium">Detik</div>
				</div>
			</div>
		</div>
	);
};

function Buy() {
	const params = useParams();
	const navigate = useNavigate();
	const [screenshotImageSrc, setScreenshotImageSrc] = useState(null);
	const [screenshotId, setScreenshotId] = useState(null);
	const [line, setLine] = useState("");
	const [showScreenshot, setShowScreenshot] = useState(false);
	const [done, setDone] = useState(false);
	const [searchParams] = useSearchParams();
	const listing = useQuery(["listing", params.listing_id], async () => {
		return await axios.get(`get_listing_detail/${params.listing_id}/`);
	});
	const status = useQuery(["status", params.listing_id], async () => {
		return await axiosWithCredentials.get(
			`can_access_buy_page/${params.listing_id}/`
		);
	});
	const voucherOk = useQuery(
		["voucherOk", searchParams.get("voucher")],
		async () => {
			return await axios.get(
				`validate_voucher/${
					searchParams.get("voucher") ? searchParams.get("voucher") : ""
				}`
			);
		},
		{
			refetchInterval: 5000,
			refetchIntervalInBackground: true,
		}
	);

	useQuery(
		["setSoftTimestamp", params.listing_id],
		async () => {
			return await axiosWithCredentials.get(
				`set_soft_timestamp/${params.listing_id}/`
			);
		},
		{ refetchInterval: 5000, refetchIntervalInBackground: true }
	);

	if (voucherOk.isLoading) return "Loading...";
	if (voucherOk.isError) navigate(`/error?err=${voucherOk.error.message}`);

	if (status.isLoading) return "Loading...";
	if (status.isError) navigate(`/error?err=${status.error.message}`);

	if (listing.isLoading) return "Loading...";
	if (listing.isError) navigate(`/error?err=${listing.error.message}`);

	const props = {
		params,
		navigate,
		status,
		screenshotImageSrc,
		setScreenshotImageSrc,
		screenshotId,
		setScreenshotId,
		showScreenshot,
		setShowScreenshot,
		line,
		setLine,
		listing,
		done,
		setDone,
		searchParams,
		voucherOk,
	};

	return (
		<div className="relative">
			{!status.data.data.canBuy && <Invalid {...props} />}
			{status.data.data.canBuy &&
				(status.data.data.status || done ? (
					<Pending />
				) : (
					<>
						<Back {...props} close={true} />
						<div className="flex flex-col mx-10 sm:mx-20 md:mx-40 xl:mx-96 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">
							<h1 className="text-center text-2xl sm:text-4xl font-bold mt-10">
								Pembayaran
							</h1>
							<Timer expireTime={status.data.data.expireTime} />
							<First {...props} />
							<Second {...props} />
							<div className="w-full flex justify-center">
								<Button {...props} />
							</div>
						</div>
					</>
				))}
		</div>
	);
}

export default Buy;
