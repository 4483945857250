function reverseString(str) {
  let splitString = str.split("");
  let reverseArray = splitString.reverse();
  let joinArray = reverseArray.join("");
  return joinArray;
}

export function Reformat(price) {
  if (price === 0) {
    return "";
  }
  let totalPriceDigits = price.toString().split("");
  totalPriceDigits = totalPriceDigits.reverse();

  let totalPriceInRupiah = "";
  for (let i = 0; i < totalPriceDigits.length; i++) {
    totalPriceInRupiah += totalPriceDigits[i];
    if (i === totalPriceDigits.length - 1) continue;
    if ((i + 1) % 3 === 0) totalPriceInRupiah += ".";
  }
  totalPriceInRupiah = reverseString(totalPriceInRupiah);

  return totalPriceInRupiah;
}

export function Deformat(price) {
  if (price === null) return null;
  let priceStr = price.toString();
  let ret = 0;
  for (let i = 0; i < priceStr.length; i++) {
    if (priceStr[i] === ".") continue;
    if (priceStr[i] < "0" || priceStr[i] > "9") continue;
    ret += parseInt(priceStr[i]);
    ret *= 10;
  }
  ret /= 10;
  return ret;
}

export default function Price({ totalPrice }) {
  let totalPriceDigits = totalPrice.toString().split("");
  totalPriceDigits = totalPriceDigits.reverse();

  let totalPriceInRupiah = "";
  for (let i = 0; i < totalPriceDigits.length; i++) {
    totalPriceInRupiah += totalPriceDigits[i];
    if (i === totalPriceDigits.length - 1) continue;
  }
  totalPriceInRupiah = parseInt(reverseString(totalPriceInRupiah));

  return (
    <div>
      <h1 className="">Rp{Reformat(totalPriceInRupiah)}</h1>
    </div>
  );
}
