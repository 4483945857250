import toast from "react-hot-toast";

function TertiaryButton({ label, onClick, onSuccess, disabled, lightHover }) {
	return (
		<button
			onClick={async () => {
				await onClick();
				if (onSuccess) {
					toast.success(onSuccess);
				}
			}}
			disabled={disabled}
		>
			<h1
				className={`text-center font-bold transition-all duration-200 text-valored decoration-valoblack/70 ${
					lightHover ? "hover:text-white" : "hover:text-valoblack"
				} z-40`}
			>
				{label}
			</h1>
		</button>
	);
}

export default TertiaryButton;
