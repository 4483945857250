import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BlueButton from "../../components/Button/BlueButton";
import Container from "./Container";
import ReactPlayer from "react-player";
import { Skeleton } from "@mui/material";
import { axios } from "../../constants";
import { useQuery } from "react-query";
import { useWindowSize } from "../../hooks/useWindowSize";

const SkinCard = ({
	uuid,
	displayName,
	imageSrc,
	videoSrc,
	weaponType,
	shownVideo,
	setShownVideo,
	setSelectedSkin,
}) => {
	return (
		<button
			onClick={() => {
				setSelectedSkin(displayName);
				setShownVideo(videoSrc);
			}}
			className="z-50 relative flex flex-col items-center justify-center"
		>
			<img
				src={imageSrc}
				alt={displayName}
				className={`min-w-[150px] h-[80px] sm:min-w-[200px] sm:h-[100px] 2xl:min-w-[300px] 2xl:h-[120px] p-4 rounded-xl object-scale-down ${
					videoSrc !== shownVideo && "opacity-30"
				} transition-all duration-300`}
			/>
		</button>
	);
};

function BundleDisplay() {
	const params = useParams();
	const bundle = useQuery(["bundle", params.bundle_uuid], async () => {
		return await axios.get(`get_bundle_details/${params.bundle_uuid}/`);
	});
	const [shownVideo, setShownVideo] = useState(null);
	const [selectedSkin, setSelectedSkin] = useState(null);
	const [volume, setVolume] = useState(0);
	const windowSize = useWindowSize();
	const navigate = useNavigate();
	const videoRef = useRef();
	useEffect(() => {
		if (!bundle.isLoading) {
			setShownVideo(bundle.data.data.data.skins[0].videoSrc);
			setSelectedSkin(bundle.data.data.data.skins[0].displayName);
		}
	}, [bundle.isLoading]);

	return (
		<div className="flex flex-col">
			{bundle.isLoading ? (
				<div className="w-full h-[600px]">
					<Skeleton variant="rectangular" width="100%" height="100%" />
				</div>
			) : (
				<div className="relative">
					<img
						src={bundle.data.data.data.imageSrc}
						alt={bundle.data.data.data.displayName}
						className="z-50 hidden sm:block w-full h-[600px] object-cover object-top"
					/>
					{bundle.data.data.data.verticalImageSrc ? (
						<img
							src={bundle.data.data.data.verticalImageSrc}
							alt={bundle.data.data.data.displayName}
							className="z-50 sm:hidden w-full h-[400px] object-cover object-top"
						/>
					) : (
						<img
							src={bundle.data.data.data.imageSrc}
							alt={bundle.data.data.data.displayName}
							className="z-50 sm:hidden w-full h-[400px] object-cover object-top"
						/>
					)}
					<div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center text-sm sm:text-base">
						<BlueButton
							label={`Cari Bundle`}
							onClick={() =>
								navigate(
									`/explore?bd=${JSON.stringify([bundle.data.data.data.uuid])}`
								)
							}
							height={windowSize.width < 640 ? 40 : 50}
							width={windowSize.width < 640 ? 200 : 240}
							rightIcon={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={2}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M8.25 4.5l7.5 7.5-7.5 7.5"
									/>
								</svg>
							}
						/>
					</div>
				</div>
			)}
			<div className=" hidden 2xl:block absolute bottom-[450px] -right-[250px] rotate-90 tracking-wider text-9xl font-black font-montserrat text-valored/50">
				VALODEALS
			</div>
			<div className="relative">
				<Container>
					<div className="absolute top-0 -left-1 w-6 h-6 bg-popup"></div>
					<div className="absolute bottom-5 left-5 w-6 h-6 bg-popup"></div>
					<div className="absolute bottom-5 left-5 w-6 h-6 bg-popup"></div>
					<div className="absolute top-10 xl:top-20 left-[60px] sm:left-[100px] w-1 h-1 bg-popup"></div>
					<div className="absolute top-10 xl:top-20 left-[140px] sm:left-[200px] w-1 h-1 bg-popup"></div>
					<div className="absolute top-10 xl:top-20 left-[220px] sm:left-[300px] w-1 h-1 bg-popup"></div>
					<div className="absolute top-10 xl:top-20 left-[300px] sm:hidden w-1 h-1 bg-popup"></div>
					<div className="hidden sm:block absolute top-10 xl:top-20 left-[400px] w-1 h-1 bg-popup"></div>
					<div className="absolute top-0 bottom-0 left-5 h-full w-[1px] bg-valogray"></div>
					<div className="pt-5 pb-20 relative flex flex-col xl:flex-row gap-y-10 gap-x-20 items-center">
						{/* <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center z-40 my-auto h-[1px] bg-valogray"></div> */}
						<div className="max-w-full flex flex-row overflow-auto xl:overflow-visible xl:flex-col sm:gap-10">
							{bundle.isLoading
								? [...Array(5).keys()].map((x) => {
										return (
											<div
												key={x}
												className="min-w-[200px] h-[100px] 2xl:min-w-[300px] 2xl:h-[120px] z-50"
											>
												<Skeleton
													variant="rounded"
													style={{ borderRadius: "16px" }}
													width="100%"
													height="100%"
												/>
											</div>
										);
								  })
								: bundle.data.data.data.skins.map((skin) => (
										<SkinCard
											key={skin.uuid}
											{...skin}
											shownVideo={shownVideo}
											setShownVideo={setShownVideo}
											setSelectedSkin={setSelectedSkin}
										/>
								  ))}
						</div>
						{bundle.isLoading ? (
							<div className="relative flex flex-col gap-y-5 w-full aspect-video outline-none">
								<div className="flex flex-row justify-between">
									<Skeleton
										variant="rounded"
										style={{ borderRadius: "16px" }}
										width="80%"
										height="40px"
									/>
								</div>
								<Skeleton variant="rounded" width="100%" height="400px" />
							</div>
						) : (
							<div className="relative flex flex-col gap-y-5 items-end w-full">
								<div className="hidden lg:block absolute -bottom-20 -right-10 w-[400px] h-[1px] bg-popup"></div>
								<div className="hidden lg:block absolute -bottom-20 -right-10 w-5 h-5 bg-valored"></div>
								<div className="hidden lg:block absolute -bottom-[60px] -right-10 w-[1px] h-40 bg-popup"></div>
								<div className="w-full flex flex-row justify-between items-center gap-x-5">
									<h1 className="font-montserrat font-medium text-lg sm:text-3xl md:text-4xl">
										{selectedSkin?.toUpperCase()}
									</h1>
									<div className="flex gap-x-3 sm:gap-x-5">
										<button
											onClick={() => setVolume(Math.max(volume - 0.05, 0))}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={2}
												stroke="currentColor"
												className="w-4 h-4"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M19.5 12h-15"
												/>
											</svg>
										</button>
										<button
											onClick={() => {
												if (volume === 0) {
													setVolume(0.1);
												} else {
													setVolume(0);
												}
											}}
										>
											{volume === 0 ? (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth={2}
													stroke="currentColor"
													className="w-6 h-6"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z"
													/>
												</svg>
											) : (
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth={2}
													stroke="currentColor"
													className="w-6 h-6"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
													/>
												</svg>
											)}
										</button>
										<button
											onClick={() => setVolume(Math.min(volume + 0.05, 1))}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={2}
												stroke="currentColor"
												className="w-4 h-4"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M12 4.5v15m7.5-7.5h-15"
												/>
											</svg>
										</button>
									</div>
								</div>
								<div className="relative z-50 w-full aspect-video outline-none">
									<ReactPlayer
										pip
										muted={volume === 0}
										loop
										playing
										volume={volume}
										width="100%"
										height="100%"
										controls={false}
										ref={videoRef}
										url={shownVideo}
									/>
								</div>
								<div className="z-40 absolute -bottom-7 right-1 bg-valogray w-[calc(100%-20px)] h-8"></div>
							</div>
						)}
					</div>
				</Container>
			</div>
			<div className="flex flex-col"></div>
		</div>
	);
}

export default BundleDisplay;
