import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../hooks/useWindowSize";

function Dropdown({ title, submenus }) {
	const [showMore, setShowMore] = useState(false);
	const buttonRef = useRef();
	const submenuRef = useRef();
	const navigate = useNavigate();
	const windowSize = useWindowSize();

	useEffect(() => {
		const handler = (e) => {
			if (buttonRef.current && !buttonRef.current.contains(e.target)) {
				setShowMore(false);
			}
		};

		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	}, [showMore]);

	useEffect(() => {
		const handler = (e) => {
			if (windowSize.width >= 1280) {
				if (buttonRef.current && buttonRef.current.contains(e.target)) {
					setShowMore(true);
				}
				if (
					buttonRef.current &&
					!buttonRef.current.contains(e.target) &&
					submenuRef.current &&
					!submenuRef.current.contains(e.target)
				) {
					setShowMore(false);
				}
			}
		};

		document.addEventListener("mouseover", handler);
		return () => {
			document.removeEventListener("mouseover", handler);
		};
	}, [windowSize]);

	return (
		<div ref={buttonRef} className="group relative w-full">
			<button onClick={() => setShowMore(!showMore)}>
				<div className="z-70 flex gap-x-2 justify-center items-center px-4 py-1 rounded-xl transition-all duration-300 group-hover:bg-white/30">
					<h1 className="font-medium">{title}</h1>
					<img src="/downarrow.png" alt="Down Arrow" className="h-3" />
				</div>
			</button>
			<div
				ref={submenuRef}
				className="-left-3 pt-5 rounded-bl-xl rounded-br-xl bg-valoblack absolute flex flex-col justify-center items-end"
			>
				<div
					className={`transition-all duration-200 ${
						showMore ? "h-1" : "h-0"
					} bg-valored w-full`}
				/>
				{submenus.map((menu, i) => {
					return (
						<button
							key={menu.id}
							onClick={() => navigate(menu.path)}
							className={`${
								i === submenus.length - 1 && "rounded-bl-xl rounded-br-xl"
							} font-medium w-64 pl-6 pr-10 ${
								showMore ? "h-11" : "h-0"
							} text-left transition-all duration-200 hover:bg-white/30`}
						>
							<h1
								className={`transition-all duration-300 ${
									showMore ? "text-white" : "text-transparent"
								}`}
							>
								{menu.title}
							</h1>
						</button>
					);
				})}
			</div>
		</div>
	);
}

export default Dropdown;
