import { useLocation, useNavigate } from "react-router-dom";

import Dropdown from "../Dropdown/Dropdown";
import Logo from "./Logo/Logo";
import Menu from "./Menu/Menu";
import MenuIcon from "./MenuIcon/MenuIcon";
import { SUBMENU } from "./constants";
import Searchbar from "./Searchbar/Searchbar";
import { axios } from "../../constants";
import { useQuery } from "react-query";
import { useState } from "react";

export default function Navbar() {
	const navigate = useNavigate();
	const location = useLocation();
	const [filter, setFilter] = useState("");
	const [showMenu, setShowMenu] = useState(false);

	const skins = useQuery(["skins"], async () => {
		return await axios.get("get_all_skins/");
	});

	const bundles = useQuery(["bundles"], async () => {
		return await axios.get("get_all_bundles/");
	});

	const props = {
		skins,
		bundles,
		filter,
		setFilter,
		showMenu,
		setShowMenu,
		navigate,
	};

	return (
		<>
			{showMenu && <Menu {...props} />}
			<div className="fixed z-80 shadow-lg bg-valoblack flex flex-row justify-between h-20 w-screen px-5 md:px-12 xl:px-20 pb-4">
				<Logo {...props} />
				<div className="flex flex-row gap-x-10 justify-end items-end text-valowhite2 font-bold">
					<div className="lg:flex flex-row gap-x-2 mb-1 hidden">
						<button
							className={`transition-all duration-300 font-bold relative px-4 py-1 rounded-xl hover:bg-yellow-400 ${
								location.pathname === "/giveaway" && "bg-yellow-400"
							}`}
							onClick={() => navigate("/giveaway")}
						>
							<img
								src="/gift.png"
								alt="Gift"
								className="w-5 h-5 absolute -top-1.5 -right-1.5"
							/>
							<h1 className="text-red-500">Giveaway</h1>
						</button>
						<button
							className="transition-all duration-300 font-medium px-4 py-1 rounded-xl hover:bg-white/30"
							onClick={() => navigate("/")}
						>
							Home
						</button>
						<Dropdown title="Bantuan" submenus={SUBMENU.Support} />
						<Dropdown title="Penjual" submenus={SUBMENU.Seller} />
						<Dropdown title="Jelajahi" submenus={SUBMENU.Explore} />
					</div>
					<Searchbar {...props} />
					<div className="ml-4 block lg:hidden">
						<MenuIcon {...props} />
					</div>
				</div>
			</div>
		</>
	);
}
