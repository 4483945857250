import { REKENING_OWNER, axios } from "../../../../constants";

import { Reformat } from "../../../../components/PriceInRupiah/Price";
import Rekening from "./Rekening/Rekening";
import Screenshot from "./Screenshot/Screenshot";
import { Skeleton } from "@mui/material";

function First(props) {
	return (
		<div className="mt-10">
			Transfer{" "}
			{props.voucherOk.isLoading ? (
				<>
					<Skeleton width="200px" height="40px" variant="rounded" />
				</>
			) : (
				<>
					Rp
					{Reformat(
						Math.max(
							0,
							props.listing.data.data.discounted_price -
								(props.voucherOk.data.data.isValid ? 200000 : 0)
						)
					)}
				</>
			)}{" "}
			ke rekening BCA a/n {REKENING_OWNER}
			<Rekening {...props} />
			<h1 className="font-bold mt-10 mb-5">Upload bukti pembayaran</h1>
			<h2 className="mb-5">
				Silahkan screenshot bukti bahwa Anda telah transfer ke rekening yang
				tertera di atas.
			</h2>
			<div className="w-full flex flex-row gap-x-5 justify-start">
				<input
					className={`w-4/5 md:w-[500px] transition-all duration-200 overflow-hidden file:mr-3 file:px-4 file:rounded-full file:py-2 
					file:border-0 file:text-valored file:font-bold file:bg-white/50 hover:file:bg-red-200`}
					onChange={(e) => {
						(async () => {
							let formData = new FormData();
							formData.append("screenshot", e.target.files[0]);
							await axios
								.post("upload_payment/", formData, {
									headers: {
										"Content-Type": "multipart/form-data",
									},
								})
								.then((resp) => {
									props.setScreenshotId(resp.data.id);
									props.setScreenshotImageSrc(resp.data.imageSrc);
								})
								.catch((error) => {
									alert(error);
								});
						})();
					}}
					type="file"
					name="photo"
				/>
				{props.screenshotImageSrc !== null && (
					<button
						onClick={() => props.setShowScreenshot(!props.showScreenshot)}
					>
						{props.showScreenshot ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
								/>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
								/>
							</svg>
						)}
					</button>
				)}
			</div>
			<Screenshot {...props} />
		</div>
	);
}

export default First;
