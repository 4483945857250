import TertiaryButton from "../../../components/Button/TertiaryButton";
import { useNavigate } from "react-router-dom";

function Index() {
	const navigate = useNavigate();

	return (
		<div className="flex justify-center px-5">
			<div className="w-[600px] flex flex-col my-20 gap-y-10">
				<h1 className="text-center text-base sm:text-xl lg:text-2xl xl:text-3xl font-bold">
					Anda akan diundang ke grup line saat pembayaran sudah dikonfirmasi
				</h1>
				<div className="flex justify-center">
					<img
						src="/hourglass.png"
						alt="Hourglass"
						className="w-[250px] sm:w-[400px]"
					/>
				</div>
				<div className="text-center text-sm md:text-base lg:text-lg xl:text-xl">
					Untuk bantuan lebih lanjut, silahkan akses laman{" "}
					<TertiaryButton
						label="Contact Us"
						onClick={() => navigate("/contact")}
					/>
				</div>
			</div>
		</div>
	);
}

export default Index;
