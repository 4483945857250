import Loading from "./Loading";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export default function SearchBar({ bundles, skins, navigate }) {
	if (skins.isLoading) return <Loading />;
	if (skins.isError) navigate(`/error?err=${skins.error.message}`);

	if (bundles.isLoading) return <Loading />;
	if (bundles.isError) navigate(`/error?err=${bundles.error.message}`);

	let options = skins.data.data.data
		.map((skin) => {
			return {
				value: skin.displayName,
				label: skin.displayName,
				imageSrc: skin.imageSrc,
				type: "skin",
			};
		})
		.concat(
			bundles.data.data.data.map((bundle) => {
				return {
					value: bundle.uuid,
					label: bundle.displayName + " Bundle",
					imageSrc: bundle.imageSrc,
					type: "bundle",
				};
			})
		);

	return (
		<div className="flex items-end h-full">
			<Select
				className="w-48 sm:w-[350px] md:w-[400px] lg:w-60 xl:w-[400px]"
				components={animatedComponents}
				options={options}
				value={null}
				onChange={(e) => {
					navigate(
						`/search?${e.type === "skin" ? "q" : "bd"}=${JSON.stringify([
							e.value,
						])}`
					);
				}}
				placeholder="Skin..."
				formatOptionLabel={(skin) => {
					return (
						<>
							{skin.imageSrc && (
								<div className="flex flex-row gap-x-4 items-center">
									<img
										src={skin.imageSrc}
										alt="skinImage"
										className={`${
											skin.type === "skin" && "h-3 sm:h-5 md:h-6 lg:h-4 xl:h-6"
										} ${
											skin.type === "bundle" &&
											"h-6 sm:h-10 md:h-12 lg:h-6 xl:h-10"
										}`}
									/>
									<h1 className="text-valoblack font-semibold text-[10px] sm:text-base md:text-lg lg:text-sm xl:text-lg">
										{skin.label}
									</h1>
								</div>
							)}
							{!skin.imageSrc && (
								<h1 className="text-valogray text-center">
									Cari skin yang Anda mau
								</h1>
							)}
						</>
					);
				}}
			/>
		</div>
	);
}
