function Container({ children }) {
	return (
		<div className="px-5 sm:px-14 md:px-20 flex flex-col items-center">
			<div className="relative w-[1200px] max-w-full px-5 flex flex-col py-8 sm:py-14 md:py-20 gap-y-10">
				{children}
			</div>
		</div>
	);
}

export default Container;
