import Back from "../Back/Back";
import Button from "../../../components/Button/RedButton";
import { useNavigate } from "react-router-dom";

function Invalid(props) {
	const navigate = useNavigate();
	return (
		<div className="w-full h-screen max-h-screen flex flex-wrap">
			<Back {...props} close={false} />
			<div className="w-full h-[calc(100%-100px)] flex flex-wrap justify-center items-center">
				<div className="w-full sm:w-4/5 md:w-2/3 lg:w-1/4">
					<img
						src="/jettkawai.png"
						alt="denied"
						className="w-full rounded-xl"
					/>
					<h1 className="w-full text-center text-2xl font-semibold">
						{props.status.data.data.status === "another_user" && (
							<>Ada pembeli lain yang sedang menggunakan laman ini</>
						)}
						{props.status.data.data.status === "expired" && (
							<>Sesi pembayaran Anda sudah berakhir</>
						)}
					</h1>
					<div className="w-full flex justify-center my-5">
						<Button
							label="Home"
							onClick={() => navigate("/")}
							width={200}
							height={50}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Invalid;
