import { useState } from "react";

function Input({
	name,
	value,
	setValue,
	label,
	width,
	fit,
	onEnter,
	defaultValue,
	rightLabel,
	autoFocus,
}) {
	const [focused, setFocused] = useState(false);

	return (
		<div className={`${fit && "w-full"} h-16 flex flex-col gap-y-1 my-1`}>
			<div className="h-5">
				<h1
					className={`${
						rightLabel && "text-right"
					} transition-all duration-400 font-semibold text-md ${
						value !== "" || focused
							? focused
								? "text-valored"
								: "text-valogray"
							: "text-transparent"
					}`}
				>
					{label}
				</h1>
			</div>
			<div className={`${fit && "w-full"}`}>
				<input
					autoFocus={autoFocus}
					name={name}
					defaultValue={defaultValue}
					type="text"
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							onEnter();
						}
					}}
					style={{
						width: JSON.stringify(width) + "px",
					}}
					onFocus={() => setFocused(true)}
					onBlur={() => setFocused(false)}
					className={`${
						fit && "w-full"
					} transition-all duration-400 outline-none px-3 py-2 ${
						value === ""
							? "placeholder:text-valogray"
							: "placeholder:text-transparent"
					} placeholder:font-bold ${
						focused && "border-2 border-valored"
					} rounded-xl bg-white`}
					placeholder={!focused && label}
					value={value}
					onChange={(e) => setValue(e.target.value)}
				/>
			</div>
		</div>
	);
}

export default Input;
